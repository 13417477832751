import React from 'react'
import importView from '../../data/importView'
const serviceRoutes = [
    {
        path: '/service/all',
        component: React.lazy(() => importView('Service','ServiceList')),
    },
    {
        path: '/service/view',
        component: React.lazy(() => importView('Service','service-viewer/ServiceViewer')),
    },
    {
        path: '/service/fieldreport',
        component: React.lazy(() => importView('Service','service-viewer/ServiceFieldReport')),
    },
    {
        path: '/service/workreport',
        component: React.lazy(() => importView('Service','service-viewer/ServiceWorkReport')),
    },
    {
        path: '/service/new',
        component: React.lazy(() => importView('Service','NewService')),
    },
    {
        path: '/service/edit',
        component: React.lazy(() => importView('Service','EditServiceForm')),
    },
    {
        path: '/service/serviceanalysis',
        component: React.lazy(() => importView('Service','ServiceAnalysis')),
    },
    {
        path: '/service/serviceedit',
        component: React.lazy(() => importView('Service','NewServiceEdit')),
    },
    {
        path: '/service/employeeanalysis',
        component: React.lazy(() => importView('Service','EmployeeAnalysis')),
    },
    
]

export default serviceRoutes
