import React from 'react'
import importView from '../../data/importView'

const reportRoutes = [
    {
        path: '/reports/customerreport',
        component: React.lazy(() => importView('Reports','CustomerReport')),
    },
    {
        path: '/reports/contractreport',
        component: React.lazy(() => importView('Reports','ContractReport')),
    },
    {
        path: '/reports/contractcallreport',
        component: React.lazy(() => importView('Reports','ContractCallReport')),
    },
    {
        path: '/reports/contractsummery',
        component: React.lazy(() => importView('Reports','ContractSummery/ContractSummery')),
    },
    {
        path: '/reports/servicecallreport',
        component: React.lazy(() => importView('Reports','ServiceCallReport')),
    },
    {
        path: '/reports/paymentreport',
        component: React.lazy(() => importView('Reports','PaymentReport')),
    },
    {
        path: '/reports/engineercallreport',
        component: React.lazy(() => importView('Reports','EngineerCallReport')),
    },
    {
        path: '/reports/serviceduereport',
        component: React.lazy(() => importView('Reports','ServiceDueReport')),
    },
    {
        path: '/reports/spareissuereport',
        component: React.lazy(() => importView('Reports','SpareIssueReport')),
    },
    {
        path: '/reports/serviceanalysis',
        component: React.lazy(() => importView('Reports','ServiceCallAnalysisReport')),
    },
    {
        path: '/reports/userlogsreport',
        component: React.lazy(() => importView('Reports','UserLogReport')),
    },
    
]

export default reportRoutes
