import React from 'react'
import importView from '../../data/importView'
const ScheduleRoutes = [
    {
        path: '/schedule/all',
        component: React.lazy(() => importView('Schedule',"ScheduleList")),
    },
]

export default ScheduleRoutes
