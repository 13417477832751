import PropTypes from 'prop-types';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Logo } from '../components/logo';
import { Container } from '@material-ui/core';

// TODO: Change subtitle text

export const AuthLayout = (props) => {
  const { children } = props;

  return (
    <Container component="main" maxWidth="xs">
      {children}
    </Container>
    );
};

AuthLayout.prototypes = {
  children: PropTypes.node
};