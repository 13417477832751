import React from 'react'
import importView from '../../data/importView'

const visitRoutes = [
    {
        path: '/visit/employee-visit-list',
        component: React.lazy(() => importView('visits','EmployeeVisitList')),
    },
     {
         path: '/visit/view-employee-visit',
         component: React.lazy(() => importView('visits','EmployeeVisitDetails')),
    },
    {
        path: '/visit/edit-employee-visit',
        component: React.lazy(() => importView('visits','EditEmployeeVisit')),
   },
    
    {
        path: '/visit/visit',
        component: React.lazy(() => importView('visits','NewEmployeeVisit')),
    },
]

export default visitRoutes
