import {
  Dashboard,
  PeopleAlt,
  MenuBook,
  ImportContacts,
  PostAdd,
  People,
  PersonAdd,
  ListAlt,
  EmojiPeople,
  Settings,
  Today,
  ViewHeadline,
  DeviceHub,
  Folder,
  Person,
} from "@material-ui/icons";
import { AttachEmailRounded } from "@mui/icons-material";

export const mainNavigation = [
  {
    name: "Dashboard",
    icon: Dashboard,
    url: `/dashboard`,
  },
  {
    name: "Employee",
    icon: EmojiPeople,
    url: `/employees`,
    navigationData: [
      {
        name: "Employee List",
        icon: ListAlt,
        url: `/employees/all`,
      },
      {
        name: "Add Employee",
        icon: PersonAdd,
        url: `/employees/new`,
      },
    ],
  },
  {
    name: "Customers",
    icon: PeopleAlt,
    url: `/customers`,
    navigationData: [
      {
        name: "Customer List",
        icon: ListAlt,
        url: `/customers/all`,
      },
      {  
        name: "Add Customer",
        icon: PersonAdd,
        url: `/customers/new`,
      },
    ],
  },
  {
    name: "Contracts",
    icon: ImportContacts,
    url: `/contracts`,
    navigationData: [
      {
        name: "Contract List",
        icon: MenuBook,
        url: `/contracts/all`,
      },
      {
        name: "Add Contract",
        icon: PostAdd,
        url: `/contracts/edit`,
      },
    ],
  },
  {
    name: 'Service Management',
    icon: Settings,
    url: `/services`,
    navigationData: [
        {
            name: 'Service List',
            iconText: 'SI',
            url: '/service/all',
        },
        {
            name: 'New Service',
            iconText: 'SU',
            url: '/service/new',
        },
        {
         name: 'Service Analysis',
         iconText: 'SU',
         url: '/reports/serviceanalysis',
     },
        //  {
        //     name: 'Employee Analysis',
        //     iconText: 'SU',
        //     path: '/service/employeeanalysis',
        // },
    ],
},
{
    name: 'Schedule Services',
    icon: Today,
    url: `/schedule`,
    navigationData: [
        {
            name: 'Schedule List',
            iconText: 'SI',
            url: '/schedule/all',
        },
    ],
},
//     {
//      name: 'Orders',
//      icon: 'folder',
//      children: [
//          {
//              name: 'Order List',
//              path: '/pages/order-list',
//              iconText: 'OL',
//          },
//          // {
//          //     name: 'View Order',
//          //     path: '/invoice/fdskfjdsuoiucrwevbgd',
//          //     iconText: 'VO',
//          // },
//      ],
//  },
// {
//     name: 'Enquiry List',
//     icon: 'today',
//     path: '/pages/query-list',
// },
// {
//     name: 'Request List',
//     icon: 'today',
//     path: '/pages/request-list',
// },
{
    name: 'Products',
    icon: ViewHeadline,
    url: `/products`,
    navigationData: [
        {
            url: '/products/list',
            iconText: 'SI',
            name:'Product List'
        },
        {
            url: '/products/new',
            iconText: 'SI',
            name:'New Product',
        },
        {
            url: '/products/view',
            iconText: 'SI',
        },
        {
            url: '/products/edit',
            iconText: 'SI',
        },
        // {
        //     name: 'New Product',
        //     iconText: 'SU',
        //     path: '/pages/new-product',
        // },
    ],
},

{
    name: 'AMC Report',
    icon: DeviceHub,
    url: `/reports`,
    navigationData: [
       
        
        {
            name: 'Customer Report',
            iconText: 'SI',
            url: '/reports/customerreport',
        },
        {
            name: 'Contract Report',
            iconText: 'SI',
            url: '/reports/contractreport',
        },
        {
            name: 'Service Call Report',
            iconText: 'SU',
            url: '/reports/servicecallreport',
        },
        {
            name: 'Contract Call Report',
            iconText: 'FP',
            url: '/reports/contractcallreport',
        },
        {
         name: 'Call Payment Report',
         iconText: 'FP',
         url: '/reports/paymentreport',
         },
        {
            name: 'Engineer Call Report',
            iconText: 'FP',
            url: '/reports/engineercallreport',
        },
        {
            name: 'User Logs Report',
            iconText: 'SI',
            url: '/reports/userlogsreport',
        },
        // {
        //     name: 'Service Due Report',
        //     iconText: 'FP',
        //     path: '/reports/serviceduereport',
        // },
        // {
        //     name: 'Spare Issue Report',
        //     iconText: 'FP',
        //     path: '/reports/spareissuereport',
        // },
    ],
},
{
    name: 'Attendance',
    icon: PeopleAlt,
    url: `/attendance`,
    navigationData: [
        {
            name: 'Dashboard',
            url: '/attendance/dashboard',
            icon: 'dashboard',
        },
        {
            name: 'Attendance List',
            iconText: 'SI',
            url: '/attendance/all',
        },
        {
            name: 'New Attendance',
            iconText: 'SU',
            url: '/attendance/new',
        },
        {
            name: 'Leave Applications',
            icon: 'today',
            url: '/attendance/leaves',
        },
     
        
       
    ],
},
{
    name:'Attendance Report',
    icon: AttachEmailRounded,
    url: `/attnreports`,
    navigationData: [
        {
            name: 'Attendance Report',
            iconText: 'SI',
            url: '/attnreports/all',
        },
        {
            name: 'Location History',
            iconText: 'SU',
            url: '/attnreports/locationhistory',
        },
        {
            name: 'Leave Report',
            iconText: 'SI',
            url: '/attnreports/leave',
        },
        {
            name: 'Visit Report',
            iconText: 'SU',
            url: '/attnreports/visit',
        },
    ]
},


//  {
//     name: 'Location Map',
//     path: '/map/map',
//     icon: 'place',
// },

//    {
//        name: 'Settings',
//        path: '/utilities/settings',
//        icon: 'build',
//    },

/*{
name: 'Session/Auth',
icon: 'security',
children: [
 {
     name: 'Sign in',
     iconText: 'SI',
     path: '/session/signin',
 },
 {
     name: 'Sign up',
     iconText: 'SU',
     path: '/session/signup',
 },
 {
     name: 'Forgot Password',
     iconText: 'FP',
     path: '/session/forgot-password',
 },
 {
     name: 'Error',
     iconText: '404',
     path: '/session/404',
 },
],
},*/
{
    name: 'Master Data',
    icon: Folder,
    url: `/masters`,
    navigationData: [
    //  {
    //      name: 'Role Settings',
    //      url: '/masters/rolesettings',
    //      iconText: 'OL',
    //  },
     {
         name: 'Email Settings',
         url: '/masters/emailsettings',
         iconText: 'OL',
     },
     {
         name: 'Product Catrgory',
         url: '/masters/productcategory',
         iconText: 'VO',
     },
     {
            name: 'Service Type',
            url: '/masters/servicetype',
            iconText: 'VO',
     },
        {
            name: 'Issue Type',
            url: '/masters/issuetype',
            iconText: 'VO',
        },
        {
         name: 'Contract Type',
         url: '/masters/contracttype',
         iconText: 'VO',
         },
         {
             name: 'Site Area',
             url: '/masters/area',
             iconText: 'VO',
         },
        {
            name: 'Designation',
            url: '/masters/designation',
            iconText: 'OL',
        },

        
    ],
},
{
    name: 'User Profile',
    url: '/profile',
    icon: Person,
},
];
