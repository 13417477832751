import React from 'react'
import importView from '../../data/importView'
const customerRoutes = [
    {
        path: '/customers/all',
        component: React.lazy(() => importView('Customers','CustomerList')),
    },
    {
        path: '/customers/view',
        component: React.lazy(() => importView('Customers','customer-viewer/CustomerViewer')),
    },
    {
        path: '/customers/edit',
        component: React.lazy(() => importView('Customers','customer-form/CustomerEditForm')),
    },
    {
        path: '/customers/new',
        component: React.lazy(() => importView('Customers','customer-form/CustomerForm')),
    },
   
]

export default customerRoutes
