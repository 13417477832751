import React from 'react'
import importView from '../../data/importView'

const reportRoutes = [
    {
        path: '/masters/designation',
        component: React.lazy(() => importView('Masters','master_designation')),
    },
    {
        path: '/masters/area',
        component: React.lazy(() => importView('Masters','master_site_area')),
    },
    {
        path: '/masters/servicetype',
        component: React.lazy(() => importView('Masters','master_service_type')),
    },
    {
        path: '/masters/issuetype',
        component: React.lazy(() => importView('Masters','master_issue_type ')),
    },
    {
        path: '/masters/contracttype',
        component: React.lazy(() => importView('Masters','master_contract_type')),
    },
    {
        path: '/masters/productcategory',
        component: React.lazy(() => importView('Masters','master_product_category')),
    },
    {
        path: '/masters/emailsettings',
        component: React.lazy(() => importView('Masters','master_email_setting')),
    },
   
   
    
]

export default reportRoutes
