import React from 'react'
import importView from '../../data/importView'

const contractRoutes = [
    {
        path: '/contracts/all',
        component: React.lazy(() => importView('Contracts','ContractList')),
    },
    {
        path: '/contracts/view',
        component: React.lazy(() => importView('Contracts','contract-viewer/ContractViewer')),
    },
    {
        path: '/contracts/print',
        component: React.lazy(() => importView('Contracts','contract-viewer/PrintContract')),
    },
    {
        path: '/contracts/new',
        component: React.lazy(() => importView('Contracts','NewContract')),
    },
    {
        path: '/contracts/edit',
        component: React.lazy(() => importView('Contracts','EditContract')),
    },
    
]

export default contractRoutes
