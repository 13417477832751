import React from 'react'
import importView from '../../data/importView'
const AttendanceReportRoutes = [
    {
        path: '/attnreports/all',
        component: React.lazy(() => importView('AttendanceReports','AttendanceReport')),
    },
    {
        path: '/attnreports/leave',
        component: React.lazy(() => importView('AttendanceReports','LeaveReport')),
    },
    {
        path: '/attnreports/visit',
        component: React.lazy(() => importView('AttendanceReports','VisitReport')),
    },
    {
        path: '/attnreports/locationhistory', 
        component: React.lazy(() => importView('AttendanceReports','LocationReport')),
    }, 
    
]

export default AttendanceReportRoutes
