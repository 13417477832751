import React from 'react'
import importView from '../../data/importView'
const StoreProductRoutes = [
    {
        path: '/spareproduct/all',
        component: React.lazy(() => importView('SpareProduct','SpareProductList')),
    },
    {
        path: '/spareproduct/view',
        component: React.lazy(() => importView('SpareProduct','SpareProductViewer')),
    },
    {
        path: '/spareproduct/new',
        component: React.lazy(() => importView('SpareProduct','SpareProductForm')),
    },
    {
        path: '/spareproduct/edit',
        component: React.lazy(() => importView('SpareProduct','SpareProductFormEdit')),
    },
]

export default StoreProductRoutes
