import axios from "axios";

const API_URL = " http://laravel.shekharkhise.in/public/api/";

const register = (username, email, password) => {
  return axios.post(API_URL + "userLogin", {
    username,
    email,
    password,
  });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "userLoginNew", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.status) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
};
