import React, { useCallback, useEffect } from "react";
import { createTheme  } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Redirect, Route, useLocation,useHistory} from "react-router-dom";
import { MainLayout } from "./layouts";
import { AuthLayout } from "./layouts/AuthLayout";
import { themeL, themeD } from "./themes";
import { mainNavigation, mainRoutes,authRoutes } from "./data";
import { Routes, RoutesWithLayout } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { GlobalCss } from "./App";
export { default as GlobalCss } from './styles/GlobalCss'
const App = () => {
  const [darkMode, setDarkMode] = React.useState(() => {
    const dark = localStorage.getItem("dark");
    if (dark) { 
      return JSON.parse(dark);
    } else {
      return false;
    }
  });
  let navigate = useHistory();
  const darkModeToggle = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("dark", !darkMode);
  };

  const themeSwitchCofig = {
    state: darkMode,
    handler: darkModeToggle,
  };
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(()=> {
    if (!currentUser) {
      navigate.push("/login");
    }
  },[])

  const appliedTheme = createTheme (darkMode ? themeD : themeL);
  return (
    <>
    {
      currentUser ? 
      
      <ThemeProvider theme={appliedTheme}>
        <GlobalCss/>
      <RoutesWithLayout
        layout={MainLayout}
        routes={mainRoutes}
        LayoutProps={{
          navigationData: mainNavigation,
          themeConfig: themeSwitchCofig,
        }}
      />
    </ThemeProvider>
    :
    <ThemeProvider theme={appliedTheme}>
      <RoutesWithLayout
        layout={AuthLayout}
        routes={authRoutes}
        LayoutProps={{
          themeConfig: themeSwitchCofig,
        }}
      />
    </ThemeProvider>
     }
     </>
  );
};

export default App;
