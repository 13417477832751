import React from 'react'
import importView from '../../data/importView'
const AttendanceRoutes = [
    {
        path: '/attendance/dashboard',
        component: React.lazy(() => importView('Attendance','Analytics')),
    },
    {
        path: '/attendance/all',
        component: React.lazy(() => importView('Attendance','AttendanceList')),
    },
    {
        path: '/attendance/leaves',
        component: React.lazy(() => importView('Attendance','LeaveApplication')),
    },
    {
        path: '/attendance/edit',
        component: React.lazy(() => importView('Attendance','EditAttendance')),
   },
    {
        path: '/attendance/new',
        component: React.lazy(() => importView('Attendance','NewAttendance')),
    },
   
]

export default AttendanceRoutes
