import React from 'react'
import importView from '../../data/importView'
const ProductRoutes = [
    {
        path: '/products/list',
        component: React.lazy(() => importView('Products','ProductList')),
    },
    {
        path: '/products/view',
        component: React.lazy(() => importView('Products','ProductViewer')),
    },
    {
        path: '/products/new',
        component: React.lazy(() => importView('Products','ProductForm')),
    },
    {
        path: '/products/edit',
        component: React.lazy(() => importView('Products','ProductFormEdit')),
    },
]

export default ProductRoutes
