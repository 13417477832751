import { lazy } from "react";


import MasterRoutes from '../views/Masters/MasterRoutes'
import contractRoutes from '../views/Contracts/ContractRoutes'
import serviceRoutes from '../views/Service/ServiceRoutes'
import employeeRoutes from '../views/Employees/EmployeeRoutes'
import customerRoutes from '../views/Customers/CustomerRoutes'
import reportsRoutes from '../views/Reports/ReportsRoutes'
import StoreProductRoutes from '../views/SpareProduct/SpareProductRoutes'
import ScheduleRoutes from '../views/Schedule/ScheduleRoutes';
import VisitRoutes from '../views/visits/EmployeeVisitRoutes';
import AttendanceRoutes from '../views/Attendance/AttendanceRoutes';
import AttendanceRepotRoutes from '../views/AttendanceReports/AttendanceReportRoutes';
import ProductRoutes from "../views/Products/productRoute";
import importView from "./importView";

export const mainRoutes = [

  {
    path: `/attnreports`,
    component: lazy(() => importView("AttendanceReports", "routes")),
    routes:AttendanceRepotRoutes
  },
  {
    path: `/visits`,
    component: lazy(() => importView("Visits", "routes")),
    routes:VisitRoutes
  },
  {
    path: `/schedule`,
    component: lazy(() => importView("Schedule", "routes")),
    routes:ScheduleRoutes
  },
  {
    path: `/products`,
    component: lazy(() => importView("Products", "routes")),
    routes:ProductRoutes
  },
  {
    path: `/storeproducts`,
    component: lazy(() => importView("StoreProducts", "routes")),
    routes:StoreProductRoutes
  },
  {
    path: `/reports`,
    component: lazy(() => importView("Reports", "routes")),
    routes:reportsRoutes
  },
  {
    path: `/masters`,
    component: lazy(() => importView("Masters", "routes")),
    routes:MasterRoutes
  },
  {
    path: `/dashboard`,
    component: lazy(() => importView(["Dashboard", 2])),
  },
  {
    path: `/employees`,
    component: lazy(() => importView("Employees", "routes")),
    routes: employeeRoutes,
  },
  {
    path: `/customers`,
    component: lazy(() => importView("Customers", "routes")),
    routes: customerRoutes,
  },
  {
    path: `/contracts`,
    component: lazy(() => importView("Contracts", "routes")),
    routes:contractRoutes
  },
  {
    path: `/service`,
    component: lazy(() => importView("Service", "routes")),
    routes:serviceRoutes
  },
  {
    path: `/attendance`,
    component: lazy(() => importView("Attendance", "routes")),
    routes: AttendanceRoutes,
  },
  

  {
    path: `/profile`,
    component: lazy(() => importView(["Users", 2])),
  },
];
export const authRoutes = [
  {
    path: `/login`,
    component: lazy(() => importView(["Login", 2])),
  },
];
