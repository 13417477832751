import React from 'react'
import importView from '../../data/importView'
const employeeRoutes = [
    {
        path: '/employees/all',
        component: React.lazy(() => importView('Employees',"EmployeeList")),
    },
     {
         path: '/employees/view',
         component: React.lazy(() => importView('Employees','EmployeeDetails')),
    },
    {
        path: '/employees/edit',
        component: React.lazy(() => importView('Employees','EditEmployee')),
   },
    
    {
        path: '/employees/new',
        component: React.lazy(() => importView('Employees','NewEmployee')),
    },
]

export default employeeRoutes
